<template>
  <div class="pay pt30">
    <div class="bg-ff">
      <div class="box">
        <div class="left">
          <div class="fs28 c-333 f500">电动自行车综合保险</div>
          <div class="fs28 c-999 pt20">保障项目</div>
        </div>
        <div class="right">
          <div class="fs28 c-blue f500">基础版二</div>
          <div class="fs28 c-999 pt20">保额</div>
        </div>
      </div>
      <div class="box">
        <div class="left">
          <div class="fs28 c-333">电车上人员意外身故、伤残</div>
        </div>
        <div class="right">
          <div class="fs28 c-333 f500">10万</div>
        </div>
      </div>
      <div class="box">
        <div class="left">
          <div class="fs28 c-333">第三者身故、伤残</div>
        </div>
        <div class="right">
          <div class="fs28 c-333 f500">10万</div>
        </div>
      </div>
      <div class="box">
        <div class="left">
          <div class="fs28 c-333">车上人员意外医疗费用</div>
        </div>
        <div class="right">
          <div class="fs28 c-333 f500">1万</div>
        </div>
      </div>
      <div class="box">
        <div class="left">
          <div class="fs28 c-333">第三者医疗费用</div>
        </div>
        <div class="right">
          <div class="fs28 c-333 f500">1万</div>
        </div>
      </div>
      <div class="box">
        <div class="left">
          <div class="fs28 c-333">第三者财产损失</div>
        </div>
        <div class="right">
          <div class="fs28 c-333 f500">5000元</div>
        </div>
      </div>
    </div>
    <div class="pt30">
      <div class="bg-ff">
        <div class="box">
          <div class="left">
            <div class="fs28 c-333 f500">起保日期</div>
          </div>
          <div class="right">
            <div class="fs28 c-blue f500">2023-03-01 00时</div>
          </div>
        </div>
        <div class="box">
          <div class="left">
            <div class="fs28 c-333">保险期数</div>
          </div>
          <div class="right">
            <div class="fs28 c-333 f500">1年</div>
          </div>
        </div>
        <div class="box">
          <div class="left">
            <div class="fs28 c-333">份数</div>
          </div>
          <div class="right">
            <div class="fs28 c-333 f500">1</div>
          </div>
        </div>
        <div class="box">
          <div class="left">
            <div class="fs28 c-333">车牌号码</div>
          </div>
          <div class="right">
            <div class="fs28 c-333 f500">
              <van-field placeholder="请输入车牌号码" :border="false" />
              <van-icon name="edit" size="20px" :readonly="true" class="fontIcon" color="#999" />
            </div>
          </div>
        </div>
        <div class="box">
          <div class="left">
            <div class="fs28 c-333">车牌幸型号</div>
          </div>
          <div class="right">
            <div class="fs28 c-333 f500">
              <van-field placeholder="如雅迪 冠能2代" :border="false" />
              <van-icon name="edit" size="20px" :readonly="true" class="fontIcon" color="#999" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="pt30">
      <div class="bg-ff">
        <div class="box">
          <div class="left">
            <div class="fs28 c-333 f500">投保人姓名</div>
          </div>
          <div class="rightFlex right">
            <div class="fs28 c-333 f500 mr10">我自己</div>
            <van-checkbox v-model="checked" checked-color="#ffc107" shape="square"></van-checkbox>
          </div>
        </div>
        <div class="box">
          <div class="left">
            <div class="fs28 c-333">投保人姓名</div>
          </div>
          <div class="right">
            <div class="fs28 c-333 f500">
              <van-field placeholder="请输入投保人姓名" :border="false" />
              <van-icon name="edit" size="20px" :readonly="true" class="fontIcon" color="#999" />
            </div>
          </div>
        </div>
        <div class="box">
          <div class="left">
            <div class="fs28 c-333">手机号码</div>
          </div>
          <div class="right">
            <div class="fs28 c-333 f500">
              <van-field placeholder="请输入手机号码" :border="false" />
              <van-icon name="edit" size="20px" :readonly="true" class="fontIcon" color="#999" />
            </div>
          </div>
        </div>
        <div class="box">
          <div class="left">
            <div class="fs28 c-333">身份信息</div>
          </div>
          <div class="right">
            <div class="fs28 c-333 f500">
              <van-field placeholder="请输入身份信息" :border="false" />
              <van-icon name="edit" size="20px" :readonly="true" class="fontIcon" color="#999" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="pt30">
      <div class="bg-ff">
        <div class="pt30 pl20 pr20">
          <div class="fs34 c-333 f500">被投保人</div>
          <div class="self mt30 mb30">
            <div class="textCenter">
              <van-icon name="friends" size="50px" color="dodgerblue" />
            </div>
            <div class="textCenter fs25 f500">我自己</div>
          </div>
        </div>
        <div class="selfBox">
          <div class="my fs28 c-fff mb20">
            自己
          </div>
          <div>
            <div class="box">
              <div class="left">
                <div class="fs28 c-333">投保人姓名</div>
              </div>
              <div class="right">
                <div class="fs28 c-333 f500">
                  <van-field placeholder="请输入投保人姓名" :border="false" />
                  <van-icon name="edit" size="20px" :readonly="true" class="fontIcon" color="#999" />
                </div>
              </div>
            </div>
            <div class="box">
              <div class="left">
                <div class="fs28 c-333">手机号码</div>
              </div>
              <div class="right">
                <div class="fs28 c-333 f500">
                  <van-field placeholder="请输入手机号码" :border="false" />
                  <van-icon name="edit" size="20px" :readonly="true" class="fontIcon" color="#999" />
                </div>
              </div>
            </div>
            <div class="box">
              <div class="left">
                <div class="fs28 c-333">身份信息</div>
              </div>
              <div class="right">
                <div class="fs28 c-333 f500">
                  <van-field placeholder="请输入身份信息" :border="false" />
                  <van-icon name="edit" size="20px" :readonly="true" class="fontIcon" color="#999" />
                </div>
              </div>
            </div>
            <div class="box">
              <div class="left">
                <div class="fs28 c-333">房屋所在</div>
              </div>
              <div class="right">
                <div class="fs28 c-333 f500">
                  <van-field placeholder="请输入房屋所在" :border="false" />
                  <van-icon name="edit" size="20px" :readonly="true" class="fontIcon" color="#999" />
                </div>
              </div>
            </div>
            <div class="box">
              <div class="left">
                <div class="fs28 c-333">详细地址</div>
              </div>
              <div class="right">
                <div class="fs28 c-333 f500">
                  <van-field placeholder="请输入详细地址" :border="false" />
                  <van-icon name="edit" size="20px" :readonly="true" class="fontIcon" color="#999" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="btnBox">
      <router-link to="/success" class="nextBtn fs30 f500 textCenter">下一步</router-link>
    </div>
  </div>
</template>

<script>

  export default {
    data () {
      return {
        checked: true,
        money: 0
      }
    },
    methods: {
    }
  }
</script>

<style lang="less" scoped>
  .pay {
    background: #F6F6F6;
  }
  .btnBox {
    padding: 10vw 0;
  }
  .nextBtn {
    display: block;
    width: 95vw;
    margin: 0 auto;
    color: #4C3527;
    background: #ffc107;
    padding: 4vw 0;
    border-radius: 50px;
  }
  .selfBox {
    width: 95vw;
    margin: 0 auto;
    border-radius: 3vw;
    border: 1px solid #eee;
    padding: 3vw;
  }
  .my {
    background: dodgerblue;
    width: 13vw;
    text-align: center;
    padding: 1vw 0;
    border-radius: 6px;
  }
  .c-blue {
    color: dodgerblue;
  }
  .rightFlex {
    display: flex;
    align-items: center;
  }
  .right {
    position: relative;
    top: 0;
  }
  .fontIcon {
    position: absolute;
    right: 0;
    top: 3.2vw;
  }
  .self {
    padding: 4vw 0;
    background: #ffc107;
    border-radius: 3vw;
    width: 25vw;
  }
  .box {
    padding: 3vw;
    border-top: 1px solid #eee;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .box:first-child {
    border-top: none;
  }
  /deep/ .van-field__control {
    text-align: right;
    padding-right: 2.5vw;
  }
</style>
